import React, {lazy} from 'react';
import {isAllowed} from '../../shared/functions';
import {Route, Switch} from 'react-router-dom';
import {useAuthenticator} from '@aws-amplify/ui-react';
import SalesChart from '../SalesChart/SalesChart';

// Lazy imports
const Home = lazy(() => import('../../Container/Home/Home'));
const WhUsers = lazy(() => import('../../Container/System/WhUsers/WhUsers'));
const SysPanel = lazy(() => import('../../Container/System/SysPanel/SysPanel'));
const ValoreMagazzino = lazy(() =>
  import('../../Container/Accounting/ValoreMagazzino/ValoreMagazzino')
);
const StatsVendite = lazy(() =>
  import('../../Container/Accounting/StatsVendite/StatsVendite')
);
const WilliamSalesTable = lazy(() =>
  import('../../Container/WilliamSalesTable/WilliamSalesTable')
);
const SkuStockThresholds = lazy(() =>
  import('../../Container/SkuStockThresholds/SkuStockThresholds')
);
const RegistrazioneIva = lazy(() =>
  import('../../Container/Accounting/RegistrazioneIva/RegistrazioneIva')
);
const EditCreation = lazy(() =>
  import('../../Container/CreatorDashboard/EditCreation')
);
const CustomCreationsPanel = lazy(() =>
  import('../../Container/CreatorDashboard/CustomCreationsPanel')
);
const CreationsManagementPanel = lazy(() =>
  import('../../Container/CreatorDashboard/CreationsManagementPanel')
);
const CreationsApprovalPanel = lazy(() =>
  import('../../Container/CreatorDashboard/CreationsApprovalPanel')
);
const BaseProductsPanel = lazy(() =>
  import('../../Container/CreatorDashboard/BaseProductsPanel')
);
const ManualLabel = lazy(() => import('../../Container/Warehouse/ManualLabel'));
const DynaTable = lazy(() => import('../DynaTable/DynaTable'));
const AttachSupplierOrder = lazy(() =>
  import('../../Container/AttachSupplierOrder/AttachSupplierOrder')
);
const CreateProductWizard = lazy(() =>
  import('../../Container/CreatorDashboard/CreateProductWizard')
);
const ShipmentCenter = lazy(() =>
  import('../../Container/ShipmentCenter/ShipmentCenter')
);
const Orders = lazy(() => import('../../Container/Orders/Orders'));
const UnimportedOrders = lazy(() =>
  import('../../Container/UnimportedOrders/UnimportedOrders')
);

const EditOrder = lazy(() => import('../../Container/EditOrder/EditOrder'));
const BrandDashboard = lazy(() =>
  import('../../Container/BrandDashboard/BrandDashboard')
);
const EditSku = lazy(() => import('../../Container/EditSku/EditSku'));
const CheckInventories = lazy(() =>
  import('../../Container/CheckInventories/CheckInventories')
);
const EditProduct = lazy(() =>
  import('../../Container/EditProduct/EditProduct')
);
const CreateMission = lazy(() =>
  import('../../Container/Missions/CreateMission/CreateMission')
);
const Alerts = lazy(() => import('../../Container/Alerts/Alerts'));
const SingleCheckInventories = lazy(() =>
  import('../../Container/SingleCheckInventories/SingleCheckInventories')
);
const InventoryRemoval = lazy(() =>
  import('../../Container/InventoryRemoval/InventoryRemoval')
);
const InventoryControl = lazy(() =>
  import('../../Container/InventoryControl/InventoryControl')
);
const RelocationStaging = lazy(() =>
  import('../../Container/Relocations/RelocationStaging')
);
const Relocations = lazy(() =>
  import('../../Container/Relocations/Relocations')
);
const HandleMyRelocation = lazy(() =>
  import('../../Container/Relocations/HandleMyRelocation')
);
const PreordersManagementPanel = lazy(() =>
  import('../../Container/Preorders/Preorders')
);
const ShowCart = lazy(() => import('../../Container/Carts/ShowCart'));
const Carts = lazy(() => import('../../Container/Carts/Carts'));
const SkuFinder = lazy(() => import('../../Container/SkuFinder/SkuFinder'));
const Attributes = lazy(() => import('../../Container/Attributes/Attributes'));
const Missions = lazy(() => import('../../Container/Missions/Missions'));
const MissionsReports = lazy(() =>
  import('../../Container/Missions/MissionsReports/MissionsReports')
);
const ShowReport = lazy(() =>
  import('../../Container/Missions/ShowReport/ShowReport')
);
const DynaFilter = lazy(() => import('../DynaFilter/DynaFilter'));
const Categories = lazy(() => import('../../Container/Categories/Categories'));
const Suppliers = lazy(() => import('../../Container/Suppliers/Suppliers'));
const Warehouse = lazy(() => import('../../Container/Warehouse/Warehouse'));
const ShelvingsViewer = lazy(() =>
  import('../../Container/ShelvingsViewer/ShelvingsViewer')
);
const SingleShelfViewer = lazy(() =>
  import('../../Container/ShelvingsViewer/SingleShelfViewer')
);
const Shelvings = lazy(() => import('../../Container/Shelvings/Shelvings'));
const HandlePreorderProduct = lazy(() =>
  import('../../Container/Preorders/HandlePreorderProduct')
);
const CreateOrder = lazy(() =>
  import('../../Container/Orders/CreateOrder/CreateOrder')
);
const Marketplaces = lazy(() =>
  import('../../Container/Marketplaces/Marketplaces')
);
const DynaDate = lazy(() => import('../DynaDate/DynaDate'));
const SkuStats = lazy(() => import('../../Container/SkuStats/SkuStats'));
const ProductBrands = lazy(() =>
  import('../../Container/ProductBrand/ProductBrand')
);
const Profile = lazy(() => import('../../Container/Profile/Profile'));
const EditMarketplace = lazy(() =>
  import('../../Container/Marketplaces/EditMarketplace/EditMarketplace')
);
const Products = lazy(() => import('../../Container/Products/Products'));
const Skus = lazy(() => import('../../Container/Skus/Skus'));
const SingleCheckInventoryLocations = lazy(() =>
  import('../../Container/SingleCheckInventories/SingleCheckInventoryLocations')
);
const DescriptionModels = lazy(() =>
  import('../../Container/DescriptionModels/DescriptionModels')
);
const Groups = lazy(() => import('../../Container/System/Groups/Groups'));
const ApiKeys = lazy(() => import('../../Container/System/ApiKeys/ApiKeys'));
const DownloadOrders = lazy(() =>
  import('../../Container/Orders/DownloadOrders')
);

const RoutesSwitch = (props) => {
  const {user} = useAuthenticator((context) => [context.user]);

  return (
    <>
      {isAllowed(
        ['admin', 'wadmin', 'commercial', 'worker', 'superworker', 'creator'],
        user?.signInUserSession?.idToken?.payload['cognito:groups']
      ) && (
        <Switch>
          <Route exact path="/" component={Home} />
          <Route
            path="/stats/profit/orders"
            component={() => (
              <DynaTable
                name={'orderProfits'}
                tableTitle={'Statistiche ordini'}
                rowsPerPage={100}
                payload={[
                  'IntervalDateFrom',
                  'IntervalDateTo',
                  'MarketplacesList',
                  'OwnersList',
                ]}
                isFilterActive={true}
                isDownloadActive={true}
                components={[
                  <DynaDate key={0} groupBy={false} title={'Interval'} />,
                  <DynaFilter
                    key={1}
                    title={'MarketplacesList'}
                    options={['Tutti', 'Amazon', 'Ebay', 'Website']}
                  />,
                ]}
              />
            )}
          />
          <Route
            path="/stats/profit/skus"
            component={() => (
              <DynaTable
                name={'skuProfits'}
                tableTitle={'Statistiche sku'}
                rowsPerPage={100}
                payload={[
                  'IntervalDateFrom',
                  'IntervalDateTo',
                  'MarketplacesList',
                  'OwnersList',
                ]}
                isFilterActive={true}
                isDownloadActive={true}
                components={[
                  <DynaDate key={0} groupBy={false} title={'Interval'} />,
                  <DynaFilter
                    key={1}
                    title={'MarketplacesList'}
                    options={['Tutti', 'Amazon', 'Ebay', 'Website']}
                  />,
                ]}
              />
            )}
          />
          <Route
            path="/stats/inventorystats"
            component={() => (
              <DynaTable
                name={'inventorystats'}
                tableTitle={'Statistiche inventario'}
                rowsPerPage={100}
                payload={[
                  'IntervalDateFrom',
                  'IntervalDateTo',
                  'MarketplacesList',
                  'OwnersList',
                ]}
                isFilterActive={true}
                isDownloadActive={true}
                components={[
                  <DynaDate key={0} groupBy={false} title={'Interval'} />,
                  <DynaFilter
                    key={1}
                    title={'MarketplacesList'}
                    options={['Tutti', 'Amazon', 'Ebay', 'Website']}
                  />,
                  <DynaFilter
                    key={2}
                    title={'OwnersList'}
                    options={['Tutti', 'FantasiaStore', 'Fonzi', 'Maad']}
                  />,
                ]}
              />
            )}
          />
          <Route
            path="/stats/customerstats"
            component={() => (
              <DynaTable
                name={'customerstats'}
                tableTitle={'Statistiche clienti'}
                rowsPerPage={100}
                payload={['IntervalDateFrom', 'IntervalDateTo']}
                isFilterActive={true}
                isDownloadActive={true}
                components={[]}
              />
            )}
          />
          <Route path="/stats/william-sales" component={WilliamSalesTable} />
          <Route
            path="/stats/sku-stock-thresholds"
            component={SkuStockThresholds}
          />
          <Route path="/preorders/:id" component={HandlePreorderProduct} />
          <Route exact path="/preorders" component={PreordersManagementPanel} />
          <Route path="/orders/unimported" component={UnimportedOrders} />
          <Route path="/orders/create" component={CreateOrder} />
          <Route path="/orders/download" component={DownloadOrders} />

          <Route exact path="/products" component={Products} />
          <Route path="/product/:id" component={EditProduct} />
          <Route path="/sales/chart/:id" component={SalesChart} />
          <Route exact path="/skus" component={Skus} />
          <Route
            path="/products/unexported"
            component={() => (
              <DynaTable
                name={'unexported_products'}
                tableTitle={'Prodotti non esportati'}
                label={'Prodotti non esportati'}
              />
            )}
          />

          <Route path="/sku/:id" component={EditSku} />

          <Route exact path="/skus/create" component={EditSku} />

          <Route exact path="/attributes" component={Attributes} />
          <Route path="/attribute/:id" component={Attributes} />

          <Route path="/brands" component={ProductBrands} />
          <Route path="/description/models" component={DescriptionModels} />

          <Route exact path="/warehouse" component={Warehouse} />
          <Route
            path="/warehouse/arrivals/edit/:id"
            component={AttachSupplierOrder}
          />
          <Route path="/warehouse/:panel" component={Warehouse} />
          <Route path="/shelvings/viewer" component={ShelvingsViewer} />
          <Route
            path="/shelvings/viewshelf/:idShelf"
            component={SingleShelfViewer}
          />
          <Route path="/shelvings/manager" component={Shelvings} />

          <Route exact path="/carts" component={Carts} />
          <Route path="/carts/show/:id" component={ShowCart} />

          <Route path="/find/sku" component={SkuFinder} />

          <Route exact path="/categories" component={Categories} />
          <Route exact path="/suppliers" component={Suppliers} />

          <Route exact path="/shipmentcenter" component={ShipmentCenter} />
          <Route path="/shipmentcenter/:module" component={ShipmentCenter} />

          <Route
            path="/bordero"
            component={() => (
              <DynaTable
                isFilterActive={true}
                isDownloadActive={true}
                name={'bordero'}
                tableTitle={'Borderò'}
              />
            )}
          />
          <Route path="/manuallabel" component={ManualLabel} />

          <Route exact path="/inventory" component={CheckInventories} />
          <Route
            exact
            path="/inventory/:id"
            component={SingleCheckInventories}
          />
          <Route
            path="/inventory/:idInventory/shelf/:idInventoryShelf"
            component={SingleCheckInventoryLocations}
          />

          <Route path="/creatordashboard/bases" component={BaseProductsPanel} />
          <Route path="/creatordashboard/baseproduct/:id" component={EditSku} />
          <Route
            path="/creatordashboard/customcreations/create"
            component={CreateProductWizard}
          />
          <Route
            path="/creatordashboard/customcreations/edit/:id"
            component={EditCreation}
          />
          <Route
            exact
            path="/creatordashboard/customcreations"
            component={CustomCreationsPanel}
          />
          <Route
            path="/creatordashboard/creationsapproval"
            component={CreationsApprovalPanel}
          />
          <Route
            path="/creatordashboard/creationsmanagement"
            component={CreationsManagementPanel}
          />
          <Route exact path="/alerts" component={Alerts} />

          <Route exact path="/missions" component={Missions} />
          <Route path="/missions/report/:id" component={ShowReport} />
          <Route path="/missions/report" component={MissionsReports} />

          <Route path="/inventoryremoval" component={InventoryRemoval} />
          <Route path="/inventorycontrol" component={InventoryControl} />
          <Route exact path="/relocation" component={Relocations} />
          <Route
            exact
            path="/relocation/staging"
            component={RelocationStaging}
          />
          <Route
            exact
            path="/relocation/myreservation"
            component={HandleMyRelocation}
          />
        </Switch>
      )}

      {isAllowed(
        ['admin'],
        user?.signInUserSession?.idToken?.payload['cognito:groups']
      ) && (
        <Switch>
          <Route path={'/accounting/sales'} component={StatsVendite} />
          <Route path="/accounting/vat" component={RegistrazioneIva} />
          <Route
            path="/accounting/warehouse/values"
            component={ValoreMagazzino}
          />
          <Route exact path="/marketplaces" component={Marketplaces} />
          <Route path="/marketplace/:id" component={EditMarketplace} />
        </Switch>
      )}

      {isAllowed(
        ['admin', 'wadmin', 'commercial', 'worker', 'superworker', 'creator'],
        user?.signInUserSession?.idToken?.payload['cognito:groups']
      ) && (
        <Switch>
          <Route path="/skus/stats/:id" component={SkuStats} />
        </Switch>
      )}

      {isAllowed(
        ['admin', 'wadmin', 'creator'],
        user?.signInUserSession?.idToken?.payload['cognito:groups']
      ) && (
        <Switch>
          <Route path="/mission/create" component={CreateMission} />
        </Switch>
      )}

      {isAllowed(
        ['admin', 'wadmin'],
        user?.signInUserSession?.idToken?.payload['cognito:groups']
      ) && (
        <Switch>
          <Route exact path="/system/panel" component={SysPanel} />
          <Route exact path="/system/groups" component={Groups} />
          <Route exact path="/system/apikeys" component={ApiKeys} />
          <Route
            exact
            path="/system/owners"
            component={() => (
              <DynaTable name={'owners'} tableTitle={'Proprietari'} />
            )}
          />
          <Route exact path="/system/users" component={WhUsers} />
        </Switch>
      )}
      {isAllowed(
        ['brand_manager'],
        user?.signInUserSession?.idToken?.payload['cognito:groups']
      ) && (
        <Switch>
          <Route exact path="/" component={BrandDashboard} />
        </Switch>
      )}

      <Switch>
        <Route exact path="/orders" component={Orders} />
        <Route path="/order/:id" component={EditOrder} />
        <Route path="/brand/dashboard" component={BrandDashboard} />
        <Route exact path="/profile" component={Profile} />
      </Switch>
    </>
  );
};

export default RoutesSwitch;
