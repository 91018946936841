import React from 'react';
import {logout} from '../../Redux/Actions/index';
import {makeStyles} from '@material-ui/core/styles';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {isAllowed} from '../../shared/functions';
import {useAuthenticator} from '@aws-amplify/ui-react';
import {setShipmentCenterPanel} from '../../Redux/Actions/index';
import theme, {default as mainTheme} from '../../config/theme';
// Material
import {withStyles} from '@material-ui/core/styles/';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
// Components
import AlertHandler from '../../Component/AlertHandler/AlertHandler';
import Drawer from '../../Component/Drawer/Drawer';
// Icons Import
import PersonIcon from '@material-ui/icons/Person';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import AssignmentIcon from '@material-ui/icons/Assignment';
import logo from '../../images/logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: '.5rem',
    alighItems: 'center',
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    minHeight: 60,
  },
  profileLinkText: {
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  logoImage: {
    margin: 5,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  defaultIconStyle: {
    textDecoration: 'none',
    color: mainTheme.icons.main,
    // [theme.breakpoints.down('xs')]: {
    //   display: 'none',
    // },
  },
  menuLink: {
    textDecoration: 'none',
    color: theme.palette.primary.dark,
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const Navbar = (props) => {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const {user, signOut} = useAuthenticator((context) => [context.user]);
  const classes = useStyles();

  const openMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  if (!props.accessToken) {
    if (
      props.location.pathname !== '/register' &&
      props.history.location.pathname.substring(0, 14) !== '/resetpassword'
    ) {
      if (props.location.pathname !== '/login') {
        props.history.push('/login');
      }
    }
  } else {
    if (!props.isActive && props.location.pathname !== '/notactive') {
      props.history.push('/notactive');
    }
    if (props.location.pathname === '/login') {
      props.history.push('/');
    }
  }

  const MissionsCalc = () => {
    let number = 0;

    if (props.relocationRows.needRelocation) {
      number++;
    }

    if (props.inventoryControlRows.length > 0) {
      number++;
    }

    if (props.inventoryRemovalRows.length > 0) {
      number++;
    }

    return number;
  };

  const logoutHandler = () => {
    props.logout();
    signOut();
    setAnchorMenu(null);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <Grid container alignItems="center">
            <Grid item xs={2} sm={6} md={4} container justify="flex-start">
              <Drawer userLevel={props.userLevel} />
            </Grid>
            <Grid item xs={false} sm={false} md={4}>
              <Link to="/">
                <img
                  src={logo}
                  className={classes.logoImage}
                  alt="logo"
                  style={{
                    maxWidth: '8.25vw',
                    maxHeight: 'auto',
                  }}
                />
              </Link>
            </Grid>

            {props.accessToken ? (
              <Grid item xs={10} sm={6} md={4} container justify="flex-end">
                {isAllowed(
                  [
                    'admin',
                    'wadmin',
                    'commercial',
                    'worker',
                    'superworker',
                    'creator',
                  ],
                  user?.signInUserSession?.idToken?.payload['cognito:groups']
                ) ? (
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <Link
                      to="/shipmentcenter"
                      className={classes.pickProductsLink}
                      onClick={(ev) => props.setShipmentCenterPanel(2)}
                    >
                      <IconButton className={classes.defaultIconStyle}>
                        <Badge
                          badgeContent={props?.shipmentCenterData?.pickProducts}
                          max={999}
                          color="primary"
                        >
                          <ShoppingCart />
                        </Badge>
                      </IconButton>
                    </Link>
                    <Link
                      to="/shipmentcenter"
                      className={classes.defaultIconStyle}
                      onClick={(ev) => props.setShipmentCenterPanel(1)}
                    >
                      <IconButton className={classes.defaultIconStyle}>
                        <Badge
                          badgeContent={props?.shipmentCenterData?.ordersReady}
                          max={999}
                          color="primary"
                        >
                          <LocalShippingIcon />
                        </Badge>
                      </IconButton>
                    </Link>
                    <Link to="/missions" className={classes.defaultIconStyle}>
                      <IconButton className={classes.defaultIconStyle}>
                        <Badge
                          badgeContent={MissionsCalc() || 0}
                          color="primary"
                        >
                          <AssignmentIcon />
                        </Badge>
                      </IconButton>
                    </Link>
                    <AlertHandler />
                  </div>
                ) : null}
                <Button
                  color="inherit"
                  style={{backgroundColor: 'transparent'}}
                  onClick={openMenu}
                >
                  <Avatar
                    alt={props.firstName}
                    src={props?.profilePicture ?? ''}
                    style={{
                      backgroundColor: props.profilePicture
                        ? ''
                        : theme.palette.secondary.main,
                    }}
                    variant={'rounded'}
                  >
                    {props?.profilePicture
                      ? null
                      : props.firstName[0] + props.lastName[0]}
                  </Avatar>
                </Button>
                <StyledMenu
                  anchorEl={anchorMenu}
                  keepMounted
                  open={Boolean(anchorMenu)}
                  onClose={closeMenu}
                >
                  <StyledMenuItem>
                    <ListItemText
                      primary={props.firstName + ' ' + props.lastName}
                    />
                  </StyledMenuItem>
                  <Link className={classes.menuLink} to="/profile">
                    <StyledMenuItem>
                      <ListItemIcon>
                        <PersonIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Profilo" />
                    </StyledMenuItem>
                  </Link>
                  {isAllowed(
                    ['admin', 'wadmin', 'commercial', 'worker', 'superworker'],
                    user?.signInUserSession?.idToken?.payload['cognito:groups']
                  ) ? (
                    <div>
                      <Link className={classes.menuLink} to="/alerts">
                        <StyledMenuItem>
                          <ListItemIcon>
                            <NotificationsIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Alert di sistema" />
                        </StyledMenuItem>
                      </Link>
                    </div>
                  ) : null}
                  <StyledMenuItem onClick={logoutHandler}>
                    <ListItemIcon>
                      <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </StyledMenuItem>
                </StyledMenu>
              </Grid>
            ) : null}
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    shipmentCenterData: state?.systemStatus?.status?.shipmentCenterData,
    accessToken: state.auth.accessToken,
    isActive: state.auth.isActive,
    relocationRows: state.config.relocationRows,
    inventoryControlRows: state.config.inventoryControlRows,
    inventoryRemovalRows: state.config.inventoryRemovalRows,
    unreadMessage: state.message.unread,
    firstName: state.auth.firstName,
    userLevel: state.auth.userLevel,
    lastName: state.auth.lastName,
    profilePicture: state.auth.profilePicture,
    auth: state.auth,
  };
};

const mapDispatchToProps = {
  logout,
  setShipmentCenterPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
